import * as Yup from 'yup';

const MSG_REQUIRED = 'layout.form.validation.required';
const MSG_OPTION_REQUIRED = 'layout.form.validation.required_select';

const email = Yup.string()
    .email('layout.form.validation.email')
    .required(MSG_REQUIRED);
const password = Yup.string()
    .min(8, 'layout.form.validation.password.min_length')
    .matches(/^(?=.*[a-z])/, 'layout.form.validation.password.lower_case')
    .matches(/^(?=.*[A-Z])/, 'layout.form.validation.password.upper_case')
    .matches(/^(?=.*[0-9])/, 'layout.form.validation.password.number')
    .matches(
        /^(?=.*[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
        'layout.form.validation.password.special_character'
    )
    .required(MSG_REQUIRED);
const passwordConfirmation = Yup.string()
    .oneOf(
        [Yup.ref('password'), undefined],
        'layout.form.validation.password.match'
    )
    .required(MSG_REQUIRED);

const lastName = Yup.string().required(MSG_REQUIRED);
const firstName = Yup.string().required(MSG_REQUIRED);
const profilePicture = Yup.string().required(MSG_REQUIRED);
const phoneRegex = /^(()|((\+[1-9][0-9]{10})|^(0[1-9][0-9]{8})))$/;
const phone = Yup.string()
    .matches(phoneRegex, 'layout.form.validation.phone')
    .nullable();

const address = Yup.string().nullable();
const additionalAddress = Yup.string().nullable();
const postCode = Yup.string()
    .matches(/^[0-9]+$/, 'layout.form.validation.numeric')
    .min(5, 'layout.form.validation.post_code.length')
    .max(5, 'layout.form.validation.post_code.length')
    .nullable();
const country = Yup.string().nullable();
const languageId = Yup.string().nullable();
const profileDescription = Yup.string().nullable();

const deleteConfirmation = Yup.string()
    .matches(/^confirmation$/)
    .required();

const checkboxGroupOneOf = (key: string) =>
    Yup.bool().oneOf(
        [Yup.ref(key), true],
        'layout.form.validation.required_select'
    );
/*
const checkboxGroupOneOf = Yup.object().shape({
    primary: Yup.bool().when("secondary", {
        is: (secondary: boolean) => !secondary,
        then: Yup.bool().oneOf([true], 'layout.form.validation.required_select')
    }),
    secondary: Yup.bool().when("primary", {
        is: (primary: boolean) => !primary,
        then: Yup.bool().oneOf([true], 'layout.form.validation.required_select')
    })
}, [
    ["primary", "secondary"],
    ["secondary", "primary"]
])
*/

const rules: { [key: string]: Yup.Schema } = {
    email,
    username: email,
    password,
    password_confirmation: passwordConfirmation,
    first_name: firstName,
    last_name: lastName,
    phone,
    phoneRequired: phone.required(MSG_REQUIRED),
    address,
    additional_address: additionalAddress,
    post_code: postCode,
    post_code_required: postCode.required(MSG_REQUIRED),
    city: Yup.object().nullable().required(MSG_REQUIRED),
    country,
    languageId,
    profile_picture: profilePicture,
    profileDescription: Yup.object().required(),
    ['accept_GCU']: Yup.bool()
        .isTrue('layout.form.validation.accept_GCU')
        .required(MSG_REQUIRED),
    ['accept_register']: Yup.bool()
        .isTrue('layout.form.validation.accept_register')
        .required(MSG_REQUIRED),
    stringRequired: Yup.string().nullable().required(MSG_REQUIRED),
    stringOptionnal: Yup.string().nullable(),
    numberRequired: Yup.number()
        .typeError('layout.form.validation.numeric')
        .required(MSG_REQUIRED),
    integerRequired: Yup.number()
        .typeError('layout.form.validation.numeric')
        .integer('layout.form.validation.numeric')
        .required(MSG_REQUIRED),
    numberOptional: Yup.number().nullable().optional(),
    positiveIntegerOptional: Yup.number()
        .typeError('layout.form.validation.numeric')
        .integer('layout.form.validation.numeric')
        .min(0, 'layout.form.validation.min.numeric')
        .optional(),
    selectRequired: Yup.string().required(MSG_OPTION_REQUIRED),
    deleteConfirmation,
    boolAny: Yup.bool().nullable(),
    boolRequired: Yup.bool().nullable().required(MSG_REQUIRED),
    boolTrueRequired: Yup.bool().isTrue(MSG_REQUIRED).required(MSG_REQUIRED),
    objectRequired: Yup.object().required(MSG_REQUIRED),
    dateRequired: Yup.string().required('layout.form.validation.date_format'),
    any: Yup.mixed().nullable().optional(),
    anyRequired: Yup.mixed().nullable().required(MSG_REQUIRED),
    arrayAtLeastOne: Yup.array()
        .min(1, MSG_OPTION_REQUIRED)
        .required(MSG_OPTION_REQUIRED),
    arrayRequired: Yup.array().required(MSG_OPTION_REQUIRED),
    languagesAllRequired: Yup.array().of(
        Yup.object({
            name: Yup.string().required(MSG_REQUIRED),
        })
    ),

    siret: Yup.string()
        .test(
            'len',
            'layout.form.validation.min.string',
            (val: string | undefined) =>
                !val || val?.length === 0 || val?.length >= 14
        )
        .test(
            'len',
            'layout.form.validation.max.string',
            (val: string | undefined) =>
                !val || val?.length === 0 || val?.length <= 14
        ).required(MSG_REQUIRED),
    siren: Yup.string()
        .test('len',
            'layout.form.validation.min.string',
            (val: string | undefined) =>
                !val || val?.length === 0 || val?.length >= 9
        )
        .test(
            'len',
            'layout.form.validation.max.string',
            (val: string | undefined) =>
                !val || val?.length === 0 || val?.length <= 9
        ).required(MSG_REQUIRED),
    vat_number: Yup.string().test(
        'len',
        'layout.form.validation.max.string',
        (val: string | undefined) => !val || val?.length === 0 || val?.length <= 16
    ).required(MSG_REQUIRED),

    iban: Yup.string().test(
        'len',
        'layout.form.validation.max.string',
        (val: string | undefined) => !val || val?.length === 0 || val?.length <= 34
    ).required(MSG_REQUIRED),
    color: Yup.string().matches(/^#[A-Fa-f0-9]{6}/, 'layout.form.validation.color'),
} as const;

const constraints: { [key: string]: any } = {
    length: {
        medium: 191,
    },
} as const;

export default rules;
export {
    rules,
    MSG_REQUIRED,
    MSG_OPTION_REQUIRED,
    constraints,
    Yup as yup,
    checkboxGroupOneOf,
};

